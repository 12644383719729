import React from 'react';
import './style.css';
import CardDescription from '../../../Components/CardDescription/CardDescription';
import { LuShieldCheck, LuTimer } from "react-icons/lu";
import { BsGraphUpArrow } from "react-icons/bs";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlus, FaMinus } from "react-icons/fa";

import img1 from './img/refrigeradores 1.jpg'
import img2 from './img/refrigeradores 2.jpg'
import img3 from './img/refrigeradores 3.jpg'

const SectionRefrigeradores = () => {
  return (
    <div className='div-section-2-refrigeracao' id='refrigeracao'>
      <div className='container-section-2-row-reverse-refrigeracao'>
        <div className='left-section-2-refrigeracao'>
          <h1>REFRIGERADORES</h1>
          <div className='text-section-refrigeracao'>
            <p>Escolha nossos refrigeradores para desempenho e durabilidade excepcionais, ideais para qualquer ambiente comercial, eles garantem a conservação perfeita dos seus produtos. </p>
            <p>Temos opções de Refrigerador ou Congelador que servirá perfeitamente para seu espaço comercial e ainda define de forma personalizada qual o tamanho e potência do equipamento. Além de poder definir se o projeto terá a opção de balcão refrigerado ou refrigerador vertical. </p>
            <p><b>Não arrisque a qualidade do seu estoque com equipamentos inadequados. Não comprometa a qualidade do seu negócio.</b> </p>
            <p><b>Preencha o formulário</b> e saiba mais sobre nossas soluções de refrigeração que se encaixam de acordo com sua demanda.</p>

          </div>

          <div className='div-alerts-section-2-refrigeracao'>
            <CardDescription icon={<LuShieldCheck className="iconCard" />} title='EFICIÊNCIA E CAPACIDADE' subtitle='para sua operação' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<LuTimer className="iconCard" />} title='QUALIDADE DE ARMAZENAMENTO' subtitle='para seus colaboradores e para seu produto' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<BsGraphUpArrow className="iconCard" />} title='CUSTOS COM ESTOQUE' subtitle='no ambiente de produção' iconTitle={<FaMinus size={10} />} />
          </div>

          <a href='#contato'>
            <button className='btn-contato-section-2-refrigeracao'>
              Solicitar contato
            </button>
          </a>
        </div>

        <div className='right-section-2-refrigeracao'>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false} swipeable={false}>
            <div className="carousel-image-container">
              <img src={img1} alt="Imagem 1" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img2} alt="Imagem 2" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img3} alt="Imagem 3" className="carousel-image" />
            </div>

          </Carousel>
        </div>

      </div>
    </div>
  )
}

export default SectionRefrigeradores;
