import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import './style.css';

const Divider2 = ({ img, title, subtitle }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <motion.div
      className='divider2'
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <div className='divider2-container'>
        <img src={img} alt="" />
        <div className='divider2-text'>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
    </motion.div>
  );
}

export default Divider2;
