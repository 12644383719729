import React from 'react';
import './style.css';
import CardDescription from '../../../Components/CardDescription/CardDescription';
import { LuShieldCheck, LuTimer } from "react-icons/lu";
import { BsGraphUpArrow } from "react-icons/bs";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlus, FaMinus } from "react-icons/fa";


import img1 from './img/corrimão 1.jpg'
import img2 from './img/corrimão 2.jpg'
import img3 from './img/corrimão 3.jpg'

const SectionCorrimao = () => {
  return (
    <div className='div-section-2-corrimao' id='corrimao'>
      <div className='container-section-2-row-corrimao'>
        <div className='left-section-2-corrimao'>
          <h1>CORRIMÃO</h1>
          <div className='text-section-corrimao'>
            <p>Nossos corrimãos oferecem segurança e elegância, proporcionando um suporte firme e acabamento sofisticado. </p>
            <p>Fabricados com materiais de altíssima qualidade para garantir durabilidade e estilo, são ideais para qualquer ambiente, seja interno ou externo. </p>
            <p><b>Entre em contato preenchendo o formulário</b> e conheça nossas opções de alta qualidade que farão a diferença.</p>

          </div>

          <div className='div-alerts-section-2-corrimao'>
            <CardDescription icon={<LuShieldCheck className="iconCard" />} title='DURABILIDADE E ELEGÂNCIA' subtitle='para sua residência' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<LuTimer className="iconCard" />} title='SEGURANÇA E ADEQUAÇÃO TÉCNICA' subtitle='para seus colaboradores e para sua empresa' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<BsGraphUpArrow className="iconCard" />} title='BUROCRACIA E RISCOS' subtitle='no ambiente comercial' iconTitle={<FaMinus size={10} />} />
          </div>

          <a href='#contato'>
            <button className='btn-contato-section-2-corrimao'>
              Solicitar contato
            </button>
          </a>
        </div>

        <div className='right-section-2-corrimao'>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false} swipeable={false} >
            <div className="carousel-image-container">
              <img src={img1} alt="Imagem 1" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img2} alt="Imagem 2" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img3} alt="Imagem 3" className="carousel-image" />
            </div>

          </Carousel>
        </div>

      </div>
    </div >
  )
}

export default SectionCorrimao;
