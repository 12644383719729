import React, { useState } from 'react';
import './Form.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    solucao: '',
    departamento: ''
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.nome.trim()) {
      newErrors.nome = 'O nome é obrigatório.';
    }

    if (!formData.telefone.trim()) {
      newErrors.telefone = 'O telefone é obrigatório.';
    } else if (!/^\d{10,11}$/.test(formData.telefone)) {
      newErrors.telefone = 'Digite um telefone válido com 10 ou 11 dígitos.';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'O e-mail é obrigatório.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Digite um e-mail válido.';
    }

    if (!formData.solucao) {
      newErrors.solucao = 'Por favor, selecione uma solução desejada.';
    }

    if (!formData.departamento) {
      newErrors.departamento = 'Por favor, selecione um departamento.';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true); // Ativar loading

      try {
        const dataToSend = {
          // mensagem: `Nome: ${formData.nome}\nTelefone: https://wa.me/+55${formData.telefone}\nEmail: ${formData.email}\nSolução Desejada: ${formData.solucao}\nDepartamento: ${formData.departamento}`,
          ...formData,
          _subject: `NOVO LEAD - FORMULÁRIO LP`,
        }
        // dataToSend.telefone =
        // const response = await fetch(`https://formsubmit.co/ajax/${process.env.REACT_APP_EMAIL}`, {
        const response = await fetch(`https://formsubmit.co/ajax/acacio@acacio.com.br`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(dataToSend)
        });

        if (response.ok) {
          setSuccess(true);
          setFormData({ nome: '', telefone: '', email: '', solucao: '', departamento: '' });
        } else {
          throw new Error('Erro ao enviar formulário');
        }
      } catch (error) {
        console.error('Erro:', error);
      } finally {
        setLoading(false); // Desativar loading
      }
    }
  };

  return (
    <div className="form-container" id="contato">
      <div className="form-text">
        <h1>Estamos preparados para oferecer a melhor solução para aprimorar o seu negócio.</h1>
        <p>
          Preencha os campos do formulário e clique em <b>Enviar Solicitação</b>. Nosso time comercial entrará em contato. Obrigado.
        </p>
      </div>
      <div className="form-fields">
        {success ? (
          <div className="success-message">
            <h2>Obrigado pela sua solicitação!</h2>
            <p>Entraremos em contato em breve.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Nome completo
              <input
                type="text"
                name="nome"
                placeholder="Digite seu nome"
                value={formData.nome}
                onChange={handleChange}
                disabled={loading} // Desabilitar input enquanto está carregando
              />
              {errors.nome && <span className="error">{errors.nome}</span>}
            </label>
            <label>
              Telefone (Whatsapp)
              <input
                type="text"
                name="telefone"
                placeholder="Digite seu telefone"
                value={formData.telefone}
                onChange={handleChange}
                disabled={loading}
              />
              {errors.telefone && <span className="error">{errors.telefone}</span>}
            </label>
            <label>
              E-mail
              <input
                type="email"
                name="email"
                placeholder="Digite seu e-mail"
                value={formData.email}
                onChange={handleChange}
                disabled={loading}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </label>
            <label>
              Tipo de solução desejada
              <select
                name="solucao"
                value={formData.solucao}
                onChange={handleChange}
                disabled={loading}
              >
                <option value="">Por favor selecione</option>
                <option value="SISTEMA DE EXAUSTÃO / COIFAS">SISTEMA DE EXAUSTÃO / COIFAS</option>
                <option value="COZINHA INDUSTRIAL / FOGÕES">COZINHA INDUSTRIAL / FOGÕES</option>
                <option value="MOBILIÁRIO">MOBILIÁRIO</option>
                <option value="REFRIGERAÇÃO">REFRIGERAÇÃO</option>
                <option value="CORRIMÃO / GUARDA-CORPO">CORRIMÃO / GUARDA-CORPO</option>
              </select>
              {errors.solucao && <span className="error">{errors.solucao}</span>}
            </label>
            <label>
              Departamento
              <select
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
                disabled={loading}
              >
                <option value="">Por favor selecione</option>
                <option value="COMERCIAL">COMERCIAL</option>
                <option value="FINANCEIRO">FINANCEIRO</option>
                <option value="ATENDIMENTO GERAL">ATENDIMENTO GERAL</option>
              </select>
              {errors.departamento && <span className="error">{errors.departamento}</span>}
            </label>
            <button type="submit" disabled={loading}>
              {loading ? 'Enviando...' : 'Enviar solicitação'}
            </button>
          </form>
        )}
      </div>
    </div>
  );


};

export default FormComponent;
