import React, { useRef } from 'react';
import Icon from './Icon'
import { motion, useInView } from "framer-motion";
import { WaveText } from '../../../Components/Animation/WaveText'
import sistema_exaustao from './icons/sistema de exaustão.png'
import cozinha from './icons/cozinha industrial.png'
import mobiliario from './icons/mobiliário.png'
import refrigerador from './icons/refrigeradores.png'
import corrimao from './icons/corrimão.png'
const Section1 = () => {

  const ref = useRef(null);

  const isInView = useInView(ref);

  const position = -600

  return (
    <div className='div-section-1'>

      {/* <motion.div
        ref={ref}
        initial={{ y: -100 }}  // Iniciar fora da tela à esquerda
        animate={{ y: isInView ? 0 : -100 }}  // Mover da esquerda para direita
        transition={{ duration: 1 }}
      > */}
      <h1>AS MELHORES SOLUÇÕES EM <WaveText text="AÇO INOX" /> VOCÊ ENCONTRA AQUI NA ACÁCIO METALÚRGICA.</h1>
      <h2>NÓS TEMOS O QUE SUA EMPRESA PRECISA HOJE. PARA SER MAIOR AMANHÃ.</h2>
      {/* </motion.div> */}

      <div className='div-icons'>

        <a href='#sistema_exaustao'>
          <Icon
            text='Sistema de Exaustão'
            time={1}
            value={position}
            imagem={sistema_exaustao}
          />
        </a>
        <a href='#cozinha_industrial'>
          <Icon
            text='Cozinha Industrial'
            time={1.2}
            value={position * 2}
            imagem={cozinha}
          />
        </a>
        <a href='#mobiliario_inox'>
          <Icon
            text='Mobiliário em Inox'
            time={1.4}
            value={position * 3}
            imagem={mobiliario}
          />
        </a>
        <a href='#refrigeracao'>
          <Icon
            text='Refrigeradores'
            time={1.6}
            value={position * 4}
            imagem={refrigerador}
          />
        </a>
        <a href='#corrimao'>
          <Icon
            text='Corrimão'
            time={1.8}
            value={position * 5}
            imagem={corrimao}
          />
        </a>

      </div>
    </div>
  )
}

export default Section1