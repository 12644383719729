import React from 'react';
import './style.css';
import CardDescription from '../../../Components/CardDescription/CardDescription';
import { LuShieldCheck, LuTimer } from "react-icons/lu";
import { BsGraphUpArrow } from "react-icons/bs";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlus, FaMinus } from "react-icons/fa";

import img1 from './img/cozinha industrial 1.jpg'
import img2 from './img/cozinha industrial 2.jpg'
import img3 from './img/cozinha industrial 3.jpg'
import img4 from './img/cozinha industrial 4.jpg'
import img5 from './img/cozinha industrial 5.jpg'

const SectionCozinhaIndustrial = () => {
  return (
    <div className='div-section-2-cozinha' id='cozinha_industrial'>
      <div className='container-section-2-row-reverse-cozinha'>
        <div className='left-section-2-cozinha'>
          <h1>COZINHA INDUSTRIAL</h1>
          <div className='text-section-cozinha'>
            <p>Modernize sua cozinha industrial com nossos equipamentos de alta performance.</p>

            <p>Projetados para suportar altas demandas, eles garantem durabilidade e excelente desempenho, aumentando a eficiência e reduzindo os custos operacionais.</p>
            <p><b>Não perca mais tempo e dinheiro com soluções ineficazes.</b></p>
            <p><b>Preencha o formulário</b> e conheça nossas soluções inovadoras que fazem a diferença no mercado.</p>
          </div>

          <div className='div-alerts-section-2-cozinha'>
            <CardDescription icon={<LuShieldCheck className="iconCard" />} title='AGILIDADE E ECONOMIA' subtitle='para sua operação' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<LuTimer className="iconCard" />} title='SEGURANÇA E CONTROLE' subtitle='para seus colaboradores e para seu produto' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<BsGraphUpArrow className="iconCard" />} title='PERDA DE TEMPO' subtitle='no ambiente de produção' iconTitle={<FaMinus size={10} />} />
          </div>

          <a href='#contato'>
            <button className='btn-contato-section-2-cozinha'>
              Solicitar contato
            </button>
          </a>
        </div>

        <div className='right-section-2-cozinha'>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false} swipeable={false}>
            <div className="carousel-image-container">
              <img src={img1} alt="Imagem 1" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img2} alt="Imagem 2" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img3} alt="Imagem 3" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img4} alt="Imagem 4" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img5} alt="Imagem 5" className="carousel-image" />
            </div>
          </Carousel>
        </div>

      </div>
    </div>
  )
}

export default SectionCozinhaIndustrial;
