import React from 'react';
import './Navbar.css'
import logo from '../../Assets/logo.jpg';
import medal from '../../Assets/medal.png'
import { FaWhatsapp } from "react-icons/fa6";

const Navbar = () => {

  return (
    <div className='navbar'>
      <div className="nav-medal">
        <a href='http://www.acacio.com.br' target='_blank' rel="noreferrer">
          <img src={medal} alt='' />
        </a>
      </div>
      <div className='nav-logo'>
        <a href='http://www.acacio.com.br' target='_blank' rel="noreferrer">
          <img src={logo} alt="" />
        </a>
      </div>
      {/* <div className="nav-login-cart"> */}
      <button className='whatsapp-button'>
        <a target='_blank' href={'https://api.whatsapp.com/send?phone=554896890061&text=Ol%C3%A1.+Preciso+de+mais+informa%C3%A7%C3%B5es+sobre+Produtos+e+Solu%C3%A7%C3%B5es+da+Ac%C3%A1cio+Metal%C3%BArgica'} rel="noreferrer">
          <FaWhatsapp size={40} />
        </a>
      </button>
      {/* </div> */}

    </div>
  )
}

export default Navbar