import React from 'react'
// import Divider1 from './Divider1'
import Section1 from './Section1'
// import Section2 from './Section2'
import FormComponent from './Form'
import Footer from '../../Components/Footer/Footer'
import Divider from './Divider'
import Divider2 from './Divider2'
import Section from './Section'
import SectionExautao from './SectionExautao'
import SectionCozinhaIndustrial from './SectionCozinhaIndustrial'
import SectionMobiliarioInox from './SectionMobiliarioInox'
import SectionRefrigeradores from './SectionRefrigeradores'
import SectionCorrimao from './SectionCorrimao'

import confianca from '../../Assets/confiar.png'
import inovacao from '../../Assets/inovação.png'
import foco from '../../Assets/foco.png'
import qualidade from '../../Assets/qualidade.png'
import reconhecimento from '../../Assets/reconhecimento.png'
import experiencia from '../../Assets/experiencia.png'

const Home = () => {
  return (
    <>
      <Section1 />
      <Divider
        img={confianca}
        title='CONFIANÇA & CREDIBILIDADE'
        subtitle='Estamos orgulhosos da nossa trajetória com mais de 30 anos de experiência, atendendo diversos parceiros em todo território nacional. Somos uma empresa idônea, respeitada e confiável pela transparência e ética em todos os projetos atendidos.'
      />

      <SectionExautao />
      <Divider2
        img={inovacao}
        title='INOVAÇÃO & TECNOLOGIA'
        subtitle='Buscamos atender as necessidades dos clientes com soluções e produtos personalizados, aplicando novas tecnologias, design e atualização dos processos.'
      />
      <SectionCozinhaIndustrial />
      <Divider
        img={foco}
        title='FOCO NA SOLUÇÃO'
        subtitle='Estamos sempre atentos para ouvir as necessidades e expectativas do cliente para oferecer o melhor atendimento, customizar o projeto, cumprir prazos e melhorar constantemente nossos serviços e suporte pós venda.'
      />
      <SectionMobiliarioInox />

      <Divider2
        img={qualidade}
        title='QUALIDADE INCOMPARÁVEL'
        subtitle='Equipe pautada no profissionalismo e na experiência, buscando sempre a excelência e segurança dos produtos e serviços desde a produção, a entrega e a instalação.'
      />

      <SectionRefrigeradores />

      <Divider2
        img={reconhecimento}
        title='RECONHECIMENTO & EFICIÊNCIA'
        subtitle='Buscamos superar as expectativas de nossos clientes realizando projetos com o mais alto comprometimento para tornar o ambiente de produção mais eficiente.'
      />
      <SectionCorrimao />

      <Divider
        img={experiencia}
        title='Experiência'
        subtitle='Manter a tradição e o prestígio conquistados pela Acácio Metalúrgica ao longo de mais de 3 décadas com planejamento e ambição pelo crescimento. Comprometendo-se com os resultados dos projetos.'
      />
      <FormComponent />
      <Footer />

    </>
  )
}

export default Home