import React from 'react';
import './style.css';
import CardDescription from '../../../Components/CardDescription/CardDescription';
import { LuShieldCheck, LuTimer } from "react-icons/lu";
import { BsGraphUpArrow } from "react-icons/bs";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { FaPlus, FaMinus } from "react-icons/fa";

import img1 from './img/sistema de exaustão 1.jpg'
import img2 from './img/sistema de exaustão 2.jpg'
import img3 from './img/sistema de exaustão 3.jpg'
import img4 from './img/sistema de exaustão 4.jpg'
import img5 from './img/sistema de exaustão 5.jpg'
import img6 from './img/sistema de exaustão 6.jpg'

const SectionExautao = () => {
  return (
    <div className='div-section-2-exaustao' id='sistema_exaustao'>
      <div className='container-section-2-row-exaustao'>
        <div className='left-section-2-exaustao'>
          <h1>SISTEMA DE EXAUSTÃO</h1>
          <div className='text-section-exaustao'>
            <p>Invista na qualidade do seu negócio com nossos sistemas de exaustão avançados. </p>
            <p>Nosso sistema de exaustão elimina odores e fumaças, controla a temperatura e reduz o ruído, resultando no aumento de produtividade.  </p>

            <p><b>Não perca tempo e recursos com sistemas ineficazes.</b></p>
            <p><b>Preencha o formulário agora</b> e descubra como melhorar seu ambiente industrial com soluções reconhecidas no mercado.</p>
          </div>

          <div className='div-alerts-section-2-exaustao'>
            <CardDescription icon={<LuShieldCheck className="iconCard" />} title='SEGURANÇA E PRATICIDADE' subtitle='para sua operação' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<LuTimer className="iconCard" />} title='QUALIDADE E EFICIÊNCIA ' subtitle='para seus colaboradores e para seu produto' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<BsGraphUpArrow className="iconCard" />} title='CHEIROS RUINS E RUÍDOS' subtitle='no ambiente de produção' iconTitle={<FaMinus size={10} />} />
          </div>

          <a href='#contato'>
            <button className='btn-contato-section-2-exaustao'>
              Solicitar contato
            </button>
          </a>
        </div>

        <div className='right-section-2-exaustao'>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false} swipeable={false}>
            <div>
              <img src={img1} alt="Imagem 1" />
            </div>
            <div>
              <img src={img2} alt="Imagem 2" />
            </div>
            <div>
              <img src={img3} alt="Imagem 3" />
            </div>
            <div>
              <img src={img4} alt="Imagem 4" />
            </div>
            <div>
              <img src={img5} alt="Imagem 5" />
            </div>
            <div>
              <img src={img6} alt="Imagem 6" />
            </div>
          </Carousel>
        </div>

      </div>
    </div>
  )
}

export default SectionExautao;
