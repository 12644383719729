
import React, { useRef } from 'react';
import { motion, useInView } from "framer-motion";
import './style.css';

const Icon = ({ text, time, value, imagem }) => {
  const ref = useRef(null);

  const isInView = useInView(ref);

  return (
    <motion.div
      ref={ref}
      initial={{ x: value }}  // Iniciar fora da tela à esquerda
      animate={{ x: isInView ? 0 : -100 }}  // Mover da esquerda para direita
      transition={{ duration: time }}
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        className='icon'>
        <img src={imagem} alt="" />
        <div className='bar'></div>

        <div className="text-swap-container">
          <motion.p
            className="text top-text"
          // initial={{ y: 0 }}
          // animate={{ y: 0 }}
          // whileHover={{ y: '-50%' }}
          // transition={{ type: "spring", stiffness: 200, damping: 16, duration: 0.35 }}
          >
            <span >{text}</span>
            <span style={{ color: '#2b7534' }}>{text}</span>
          </motion.p>
        </div>

      </motion.div>
    </motion.div>
  )
}

export default Icon;
