import React from 'react';
import { FaGlobe, FaInstagram, FaMapMarkerAlt, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';
import medal from '../../Assets/medal.png'
import logo from '../../Assets/logo.jpg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section1">
        <div className="footer-logo">
          <img src={logo} alt="" />
        </div>
        <div className="footer-info">
          <p className='iconTextLine'><FaGlobe /> <a href='https://www.acacio.com.br' target='_blank' rel="noreferrer">www.acacio.com.br</a></p>
          <p className='iconTextLine'><FaInstagram /> <a href='https://www.instagram.com/acaciometalurgica/' target='_blank' rel="noreferrer">acaciometalurgica</a></p>
        </div>
      </div>
      <div className="footer-section">
        <div className="footer-badge">
          <img src={medal} alt="30 Anos" />
        </div>
      </div>
      <div className="footer-section2">
        <p className='iconTextLine' style={{ whiteSpace: 'nowrap' }}><FaMapMarkerAlt /><a href='https://maps.app.goo.gl/vW33nS8NBaD1gfzK8' target='_blank' rel="noreferrer"> Rua Edelberto de Oliveira, 1004, Florianópolis</a> </p>
        {/* <p>CEP 88095-710</p> */}
        <p className='iconTextLine'><FaEnvelope /> acacio@acacio.com.br</p>
        <p className='iconTextLine'><FaPhone /> (48) 3240 9333</p>
        <a className='iconTextLine' target='_blank' href={'https://api.whatsapp.com/send?phone=554896890061&text=Ol%C3%A1.+Preciso+de+mais+informa%C3%A7%C3%B5es+sobre+Produtos+e+Solu%C3%A7%C3%B5es+da+Ac%C3%A1cio+Metal%C3%BArgica'} rel="noreferrer"><FaWhatsapp /> (48) 99689 0061</a>
      </div>
    </footer>
  );
};

export default Footer;
