import React from 'react';
import { motion } from 'framer-motion';

export const WaveText = ({ text }) => {
  const letters = Array.from(text);

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: [0, -20, 0], // Efeito de onda
      transition: {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    hidden: {
      opacity: 0,
      y: 0,
    },
  };

  return (
    <motion.span
      // className="wave-text"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      {letters.map((letter, index) => (
        <motion.span style={{ color: '#005133' }} key={index} variants={child}>
          {letter}
        </motion.span>
      ))}
    </motion.span>
  );
};


