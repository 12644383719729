import React from 'react'
import './CardDescription.css'


const CardDescription = ({ icon, title, subtitle, iconTitle }) => {
  return (
    <div className="seguranca-container">
      {icon}
      <div className="text-container">
        <p className="title">
          <span className='circle-icon'>
            <span className='text-icon'>{iconTitle}</span>
          </span>
          {title}
        </p>
        <small className="subtitle">{subtitle}</small>
      </div>
    </div>
  )
}

export default CardDescription