import React from 'react';
import './style.css';
import CardDescription from '../../../Components/CardDescription/CardDescription';
import { LuShieldCheck, LuTimer } from "react-icons/lu";
import { BsGraphUpArrow } from "react-icons/bs";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlus, FaMinus } from "react-icons/fa";

import img1 from './img/mobiliário 1.jpg'
import img2 from './img/mobiliário 2.jpg'
import img3 from './img/mobiliário 3.jpg'
import img4 from './img/mobiliário 4.jpg'

const SectionMobiliarioInox = () => {
  return (
    <div className='div-section-2-mobiliario' id='mobiliario_inox'>
      <div className='container-section-2-row-mobiliario'>
        <div className='left-section-2-mobiliario'>
          <h1>MOBILIÁRIO EM INOX</h1>
          <div className='text-section-mobiliario'>
            <p>Atualize seu espaço agora mesmo com nosso mobiliário inox! </p>
            <p>Combinando durabilidade, praticidade e comodidade com a limpeza e organização do seu espaço de produção, você ganha tranquilidade otimizando o tempo e economizando com custos desnecessários.</p>
            <p><b>Não deixe para depois a melhoria do seu ambiente.</b></p>
            <p><b>Preencha o formulário</b> e conheça nossas opções de alta qualidade que transformarão seu negócio.</p>
          </div>

          <div className='div-alerts-section-2-mobiliario'>
            <CardDescription icon={<LuShieldCheck className="iconCard" />} title='ORGANIZAÇÃO E PERSONALIZAÇÃO' subtitle='para sua operação' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<LuTimer className="iconCard" />} title='DURABILIDADE E FACILIDADE' subtitle='para seus colaboradores e para seu produto' iconTitle={<FaPlus size={10} />} />
            <CardDescription icon={<BsGraphUpArrow className="iconCard" />} title='DEPRECIAÇÃO E PREJUÍZOS' subtitle='no ambiente de produção' iconTitle={<FaMinus size={10} />} />
          </div>

          <a href='#contato'>
            <button className='btn-contato-section-2-mobiliario'>
              Solicitar contato
            </button>
          </a>
        </div>

        <div className='right-section-2-mobiliario'>
          <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} showStatus={false} swipeable={false}>
            <div className="carousel-image-container">
              <img src={img1} alt="Imagem 1" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img2} alt="Imagem 2" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img3} alt="Imagem 3" className="carousel-image" />
            </div>
            <div className="carousel-image-container">
              <img src={img4} alt="Imagem 4" className="carousel-image" />
            </div>

          </Carousel>
        </div>

      </div>
    </div>
  )
}

export default SectionMobiliarioInox;
